import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'

const SEO = ({ data }) => {
  const {
    metaTitleContainer,
    metaTagContainer,
    metaLinkContainer,
    metaJsonLdContainer,
  } = data

  return (
    <Helmet>
      {parse(metaTitleContainer)}
      {parse(metaTagContainer)}
      {parse(metaLinkContainer)}
      {parse(metaJsonLdContainer)}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  data: PropTypes.object,
}

SEO.defaultProps = {
  data: {},
}
