import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

import 'twin.macro'

export const AnimateIn = ({ children }) => {
  return React.Children.map(children, (child, index) => (
    <AnimateInElement key={index}>{child}</AnimateInElement>
  ))
}

AnimateIn.propTypes = {
  children: PropTypes.node,
}

AnimateIn.defaultProps = {
  children: null,
}

export const AnimateInElement = ({ children }) => {
  const controls = useAnimation()
  const [ref, inView, entry] = useInView()

  // bounding client rect used to calculate if above viewport or not
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    } else if (
      entry &&
      entry.boundingClientRect &&
      entry.boundingClientRect.top > 0
    ) {
      controls.start('hidden')
    }
  }, [controls, entry, inView])

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ type: 'tween', ease: 'easeOut', duration: 1 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 40 },
      }}
    >
      {children}
    </motion.div>
  )
}

AnimateInElement.propTypes = {
  children: PropTypes.node,
}

AnimateInElement.defaultProps = {
  children: null,
}
